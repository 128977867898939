<!--
 * @Author: wangyibin
 * @Date: 2022-04-07 16:10:51
 * @LastEditTime: 2022-09-14 17:34:24
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\components\Footer.vue
-->
<template>
    <div id="footerModal" ref="footerModal">
        <div class="footer">
            <div class="footer-content-top">
                <div class="footer-content-top-body">
                    <div class="body-left">
                        <ul class="nav-list">
                            <li
                                v-for="(item,i) in navlist"
                                :key="item.id"
                            >
                                <h3 class="head"><span>{{item.title}}</span></h3>
                                <div class="link-block">
                                    <div
                                        class="link-block-sub"
                                        v-for="(t,index) in item.children"
                                        :key="t.id"
                                    >
                                        <span @click="gotoModal(t.link,i)">{{t.title}}</span>
                                        <div
                                            v-if="t.children.length>0"
                                            class="link-block-sub-next"
                                            :class="t.children.length>0&&index!=0?'mt-10':''"
                                        >
                                            <span
                                                v-for="c in t.children"
                                                :key="c.id"
                                                @click="gotoModal(c.link,i)"
                                            >{{c.title}}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="copy-box">
                            <span>客服电话：400-600-9100</span>
                            <span>在线客服：<a href="https://www.cluan.cn/im_client/?web_plugin_id=30493" target="_blank">点击咨询</a></span>
                            <span>客服邮箱：<a href="mailto:cs@cluan.cn">cs@cluan.cn</a></span>
                        </div>
                        <div class="copy-txt">
                            <span>©Copyright 2023 苍鸾（广州）信息技术有限公司</span>
                            <span>
                                <a
                                    style="text-decoration:none;"
                                    href="https://beian.miit.gov.cn/#/Integrated/index"
                                    target="_blank">粤ICP备2023103271号</a>
                            </span>
                        </div>
                    </div>
                    <div class="body-right">
                        <div class="share-block">
                            <div class="share-block-img"><img :src="rqcode" alt=""></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="isblock">
            <div class="back-top" :class="flag?'show-hide':''">
                <img @click="gotoTop" :src="iconTop" alt="顶部" title="返回顶部">
                <img v-if="isFlag" @click="goBack" :src="iconBack" alt="返回" title="返回">
            </div>
        </template>
        <template v-else>
            <div class="back-top show-hide">
                <img @click="gotoTop" :src="iconTop" alt="顶部" title="返回顶部">
                <img @click="goBack" :src="iconBack" alt="返回" title="返回">
            </div>
        </template>
    </div>
</template>

<script>
import ContactUs from './ContactUs'
export default {
    name: 'Footer',
    components: {ContactUs},
    data() {
        return {
            flag: false,
            path:'',
            isFlag: false,
            isblock: false,
            navlist: [
                {
                    id:'1',
                    title: '产品服务',
                    name: '#',
                    children: [
                        {id:'1-1',title:'服务查询',link:'productserves',children: []},
                        {id:'1-2',title:'运费试算',link:'freighttrial',children: []},
                    ]
                },
                // {
                //     id:'2',
                //     title: '新闻中心',
                //     name: '#',
                //     children: [
                //         {id:'2-1',title:'公司动态',link:'#',children: []},
                //         {id:'2-2',title:'行业新闻',link:'#',children: []},
                //     ]
                // },
                {
                    id:'3',
                    title: '关于苍鸾(广州)信息技术',
                    name: '#',
                    children: [
                        {id:'3-1',title:'公司介绍',link:'companyintro',children: []},
                        {id:'3-2',title:'人才招聘',link:'recruit',children: []},
                    ]
                },
                {
                    id:'4',
                    title: '政策与条款',
                    name: '#',
                    children: [
                        {id:'4-1',title:'隐私政策',link:'userprivacy',children: []},
                        {id:'4-2',title:'用户使用条款',link:'useruse',children: []},
                    ]
                },
            ]
        }
    },
    watch: {
        $route(){
            this.path = this.$route.path
        },
        path(newVal){
            if(newVal){
                this.init()
            }
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        init(){
            const {path} = this.$route
            const bl = path.includes('productdesc')
            if(bl){
                this.flag = true
                this.isFlag = true
                this.isblock = false
            }else{
                this.flag = false
                this.isFlag = false
                this.isblock = true
            }
            this.$nextTick(()=>{
                this.getFooterHeigth()
                this.showHide(bl)
            })
        },
        getFooterHeigth(){
            setTimeout(()=>{
                const footerTop = document.querySelector(`#footerModal`).offsetTop
                this.$store.commit('SET_FOOTERTOP',footerTop)
            },100)
        },
        showHide(bl){
            if(!bl){
                window.addEventListener('scroll',()=>{
                    const {path} = this.$route
                    if(!path.includes('/collectionInfo')&&!path.includes('/selfdeliveryInfo')&&!path.includes('/mailInfo')&&!path.includes('/embargoInfo')){
                        let scrolltop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
                        scrolltop>800?this.flag = true:this.flag = false
                    }
                })
            }
        },
        gotoModal(link){
            this.routeToPage(link)
        },
        goBack(){
            this.$router.replace('/productserves')
        }
    }
}
</script>

<style lang="less" scoped>
.footer{
    position: relative;
    width: 1200px;
    margin: 0 auto;
    .mt-10{
        margin-top: 10px;
    }
    .footer-content-top{
        .footer-logo{
            width: 169px;
            height: 61px;
            overflow: hidden;
            cursor: pointer;
            img{
                width: 100%;
            }
        }
        .footer-content-top-body{
            display: flex;
            justify-content: space-between;
            padding: 50px 0;
            .head{
                font-size: 20px;
                margin-bottom: 10px;
                font-weight: bold;
                color: #fff;
                span{
                    display: inline-block;
                }
            }
            .body-left{
                width: 80%;
                .nav-list{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    border-bottom: 1px solid #fff;
                    padding-bottom: 20px;
                    li{
                        width: 16%;
                        text-align: center;
                        .link-block{
                            font-size: 12px;
                            display: flex;
                            flex-direction: column;
                            line-height: 160%;
                            justify-content: center;
                            align-items: center;
                            span{
                                display: inline-block;
                                cursor: pointer;
                                color: #fff;
                                border-bottom: 1px solid #1a76ba;
                                &:hover{
                                    border-bottom-color: #fff;
                                }
                            }
                            .link-block-sub{
                                display: flex;
                                align-items: baseline;
                                color: #959499;
                                font-size: 14px;
                                &:first-child{
                                    margin-top: 0;
                                }
                                .link-block-sub-next{
                                    display: flex;
                                    flex-direction: column;
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                }
                a{
                    color:#fff;
                    text-decoration: underline;
                }
                .copy-box{
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                    color: #fff;
                    font-size: 14px;
                    box-sizing: border-box;
                    padding: 0 50px;
                }
                .copy-txt{
                    display: flex;
                    justify-content: space-around;
                    color: #fff;
                    margin-top: 60px;
                    font-size: 12px;
                }
            }
            .body-right{
                width: 20%;
                display: flex;
                justify-content: flex-end;
                box-sizing: border-box;
                padding-right: 50px;
                .share-block{
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    .share-block-img{
                        width: 100px;
                        overflow: hidden;
                        margin-bottom: 10px;
                        img{
                            width: 100%;
                        }
                    }
                }
                .phone-number{
                    margin-top: 20px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #969699;
                }
            }
        }
    }
    .footer-content-bottom{
        padding-top: 45px;
        padding-left: 65px;
        background:#161616;
        display: flex;
        align-items: baseline;
        color: #afafaf;
        font-size: 14px;
        height: 130px;
        a{
            color:#afafaf;
        }
    }
}
.back-top{
    position: fixed;
    bottom:250px;
    right:50px;
    display: none;
    transition: all 2s;
    cursor: pointer;
    z-index: 9999;
    &.show-hide{
        display: block !important;
    }
    img{
        width:45px;
        height:45px;
    }
}
</style>
